import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import classes from './styles.module.css';
import { useNotification } from '../../../../../shared/helpers/notification';
import { StateEnum } from '../ServicesForm/utils';
import { useApi } from '../../../../../shared/helpers/api';
import Radio from '../../../../../shared/components/Radio';
import PillWithIcon from '../../../../../shared/components/Pill/PillAlternative';
import ActionBox from '../ActionBox';
import { parseBackRegularHours, parseRegularHours } from '../OpenHoursForm/utils';
import AdditionalHoursModal from '../AdditionalHoursModal';
import AdditionalHoursCards from '../AdditionalHoursCards';
import Tooltip from '../../../../../shared/components/Tooltip';
import { moreHoursInit } from './utils';
import { handleApiError } from '../../Settings/ChangePassword/utils';
import { apiRoutes } from '../../../../../shared/helpers/apiRoutes';

const AdditionalHours = ({
  form,
  setForm,
  businessProfileId,
  setState,
  isEdit,
  editHandler,
  adminPreview,
  isReadOnlySubPlan,
}) => {
  const { t } = useTranslation();
  const { api } = useApi();
  const { showNotification } = useNotification();

  const [showModal, setShowModal] = useState(false);
  const [errors, setErrors] = useState([]);
  const [modalTitle, setModalTitle] = useState('');
  const [openHours, setOpenHours] = useState([]);
  const [additionalBusinessHours, setAdditionalBusinessHours] = useState([]);

  const additionalHoursTypesList = useMemo(
    () => additionalBusinessHours.map((el) => el.hoursTypeId),
    [additionalBusinessHours],
  );

  const additionalHours = useMemo(() => {
    if (!form.categories.primaryCategory.moreHoursTypes) return [];
    return form.categories.primaryCategory.moreHoursTypes;
  }, [form.categories.primaryCategory.moreHoursTypes]);

  const isTooltipHidden = adminPreview || isReadOnlySubPlan;

  const modalHandler = () => {
    setShowModal((prev) => !prev);
    setOpenHours(moreHoursInit);
  };

  const onSelectHandler = (id) => {
    if (!isEdit) return;
    const found = additionalBusinessHours.find((el) => el.hoursTypeId === id);
    if (found) {
      setAdditionalBusinessHours((prev) => {
        const filtered = prev.filter((el) => el.hoursTypeId !== id);
        return filtered;
      });
    } else {
      const foundHoursType = form.categories.primaryCategory.moreHoursTypes.find((el) => el.hoursTypeId === id);
      const name = foundHoursType.localizedDisplayName;
      setModalTitle(name);
      setShowModal(true);
      setOpenHours(moreHoursInit);
    }
  };
  const handleSave = async () => {
    const parsedPayload = additionalBusinessHours.map((el) => ({
      ...el,
      periods: parseBackRegularHours(el.periods, form.openInfo.status),
    }));
    try {
      setState(StateEnum.loading);

      await api.post(apiRoutes.businessProfile.updateBusinessProfileHours, {
        businessProfileId,
        openInfo: { status: form.openInfo.status },
        regularHours: parseBackRegularHours(form.regularHours, form.openInfo.status),
        moreHours: parsedPayload,
      });
      showNotification({ label: t('global.success'), message: t('global.saveWithSuccess'), type: 'success' });
      setForm((prev) => ({ ...prev, moreHours: parsedPayload }));
    } catch (err) {
      handleApiError({ err, t, showNotification });
    }
    setState(StateEnum.success);
    editHandler();
  };

  const moreHoursSaveHandler = () => {
    const foundHoursType = form.categories.primaryCategory.moreHoursTypes.find(
      (el) => el.localizedDisplayName === modalTitle,
    );
    const moreHoursTypeId = foundHoursType.hoursTypeId;
    const name = foundHoursType.localizedDisplayName;
    setAdditionalBusinessHours((prev) => {
      if (!prev.length) {
        return [{ hoursTypeId: moreHoursTypeId, localizedDisplayName: name, periods: openHours }];
      }

      if (prev.find((el) => el.hoursTypeId === moreHoursTypeId)) {
        return prev.map((el) => (el.hoursTypeId === moreHoursTypeId ? { ...el, periods: openHours } : el));
      }
      return [...prev, { hoursTypeId: moreHoursTypeId, periods: openHours, localizedDisplayName: name }];
    });
    modalHandler();
  };

  const handleCancel = () => {
    const parsedFormHours = form.moreHours?.length
      ? form.moreHours.map((el) => ({ ...el, periods: parseRegularHours(el.periods) }))
      : [];
    setAdditionalBusinessHours(parsedFormHours);
    editHandler();
  };

  const closeModalHandler = () => {
    setShowModal(false);
    setOpenHours(moreHoursInit);
  };

  const onEditMoreHoursHandler = (id) => {
    if (!isEdit) return;
    const found = additionalBusinessHours.find((el) => el.hoursTypeId === id);
    if (!found) return;
    setModalTitle(found.localizedDisplayName);
    setOpenHours(found.periods);
    setShowModal(true);
  };

  useEffect(() => {
    setOpenHours(moreHoursInit);
  }, []);

  useEffect(() => {
    if (!form.moreHours) return;
    const hours = form.moreHours.map((el) => ({
      ...el,
      periods: parseRegularHours(el.periods),
      localizedDisplayName: additionalHours.find((type) => type.hoursTypeId === el.hoursTypeId).localizedDisplayName,
    }));
    setAdditionalBusinessHours(hours);
  }, [form.moreHours]);

  return (
    <div>
      <div className={classes.subTitle}>{t('businessProfileEdit.additionalHoursSubTitle')}</div>
      {showModal && (
        <AdditionalHoursModal
          additionalHoursModalCancelHandler={closeModalHandler}
          isEdit={isEdit}
          modalHandler={modalHandler}
          modalTitle={modalTitle}
          moreHoursSaveHandler={moreHoursSaveHandler}
          openHours={openHours}
          setOpenHours={setOpenHours}
          setErrors={setErrors}
          errors={errors}
        />
      )}
      <div className={classes.moreHoursExistWrapper}>
        {additionalBusinessHours.map((el) => (
          <AdditionalHoursCards
            isEdit={isEdit}
            adminPreview={adminPreview}
            key={el.hoursTypeId}
            additionalHours={el}
            onSelectHandler={onEditMoreHoursHandler}
          />
        ))}
      </div>
      <div className={clsx(classes.wrapper)}>
        {additionalHours.map((type) => (
          <Tooltip
            key={type.hoursTypeId}
            tooltipStyle={clsx(classes.tooltipStyle, isTooltipHidden && classes.displayNone)}
            text={t('businessProfileEdit.inOrderToSelect')}
            isEdit={isEdit}
          >
            <div>
              <PillWithIcon
                onClick={() => onSelectHandler(type.hoursTypeId)}
                className={clsx(
                  classes.servicePill,
                  additionalHoursTypesList.includes(type.hoursTypeId) && classes.selectedService,
                  !isEdit && classes.noUserEvents,
                )}
              >
                <Radio
                  onClick={() => onSelectHandler(type.hoursTypeId)}
                  value={additionalHoursTypesList.includes(type.hoursTypeId)}
                  className={clsx(classes.radio, !isEdit && classes.noUserEvents)}
                />
                <div className={classes.displayName}>{type.localizedDisplayName}</div>
              </PillWithIcon>
            </div>
          </Tooltip>
        ))}
      </div>

      {isEdit && <ActionBox handleCancel={handleCancel} handleSave={handleSave} />}
    </div>
  );
};
export default AdditionalHours;
