import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import ServiceAreaSelect from '../ServiceAreaSelect';
import { ReactComponent as CloseIcon } from '../../../../../shared/assets/close.svg';
import { useApi } from '../../../../../shared/helpers/api';
import { useNotification } from '../../../../../shared/helpers/notification';
import { StateEnum } from '../../../../../shared/helpers/state';
import ActionBox from '../ActionBox/index';
import PillWithIcon from '../../../../../shared/components/Pill/PillAlternative';
import Tooltip from '../../../../../shared/components/Tooltip';
import { parseAreas } from './utills';
import { handleApiError } from '../../Settings/ChangePassword/utils';
import { apiRoutes } from '../../../../../shared/helpers/apiRoutes';

const ServiceAreaForm = ({
  className,
  form,
  setForm,
  businessProfileId,
  setState,
  isEdit,
  editHandler,
  adminPreview,
}) => {
  const { t } = useTranslation();
  const { api } = useApi();
  const { showNotification } = useNotification();

  const [pickedAreas, setPickedAreas] = useState([]);

  const handleArea = (value) => {
    if (!value) return;

    setPickedAreas((prev) => [...prev, value]);
  };

  const handleDelete = (el) => {
    if (!isEdit) return;
    setPickedAreas((prev) => prev.filter((prevEl) => prevEl.placeId !== el.placeId));
  };

  const handleSave = async () => {
    try {
      setState(StateEnum.loading);
      const parsedAreas = pickedAreas.filter((el) => Boolean(el)).map((el) => el.placeId);
      await api.post(apiRoutes.businessProfile.updateBusinessProfileServiceArea, {
        businessProfileId,
        serviceArea: parsedAreas,
      });
      showNotification({ label: t('global.success'), message: t('global.saveWithSuccess'), type: 'success' });
    } catch (err) {
      handleApiError({ err, showNotification, t });
    }
    setState(StateEnum.success);
    setForm((prev) => ({ ...prev, serviceArea: pickedAreas || [] }));
    editHandler();
  };

  const handleCancel = () => {
    editHandler();
    setPickedAreas(parseAreas(form.serviceArea));
  };

  useEffect(() => {
    setPickedAreas(parseAreas(form.serviceArea));
  }, [form.serviceArea]);

  return (
    <div className={clsx(classes.wrapper, className)}>
      {!adminPreview && (
        <ServiceAreaSelect areas={pickedAreas} className={classes.select} onAreaChange={handleArea} isEdit={isEdit} />
      )}
      {Boolean(pickedAreas.length && pickedAreas.every((el) => el?.placeName)) && (
        <div>
          <div className={classes.pickedAreasTitle}>{t('businessProfileEdit.selectedServiceAreas')}</div>
          <div className={classes.pickedAreasList}>
            {pickedAreas.map((el) => (
              <Tooltip
                key={el.placeId}
                tooltipStyle={clsx(classes.tooltipStyle, adminPreview && classes.displayNone)}
                isEdit={isEdit}
                text={t('businessProfileEdit.inOrderToDelete')}
              >
                <PillWithIcon className={classes.pill}>
                  {el.placeName}
                  <CloseIcon
                    onClick={() => handleDelete(el)}
                    className={clsx(classes.closeIcon, isEdit && classes.editIcon, adminPreview && classes.displayNone)}
                  />
                </PillWithIcon>
              </Tooltip>
            ))}
          </div>
        </div>
      )}
      {isEdit && <ActionBox handleCancel={handleCancel} handleSave={handleSave} />}
    </div>
  );
};

export default ServiceAreaForm;
