import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import Modal from '../../../../../shared/components/Modal';
import { averageData, basicData, fullData } from '../ProfileCompletion/utils';

const ProfileCompletionModal = ({ modalHandler }) => {
  const { t } = useTranslation();
  const dataSet = [...basicData, ...averageData, ...fullData];
  return (
    <Modal modalTitle={t('dashboard.profileCompletionModal')} contentStyle={classes.modalStyle} onCancel={modalHandler} >
      <div className={classes.howItWorks}>
        <div className={classes.title}>{t('dashboard.howItWorks')}</div>
        <div className={classes.description}>{t('dashboard.howItWorksDescription')}</div>
      </div>
      <div className={classes.completionScaleBox}>
        {dataSet.map((el) => (
          <div>
            <div className={classes.dataTitle}>{t(el.name)}</div>
            <ul className={classes.list}>
              {el.list.map((item) => (
                <li className={classes.dataRow}>
                  <div className={classes.dataItem}>
                    <div className={classes.dataItemTitle}>{t(item.name)}</div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </Modal>
  );
};
export default ProfileCompletionModal;
