import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import clsx from 'clsx';
import classes from './styles.module.css';
import { useNotification } from '../../../../shared/helpers/notification';
import Input from '../../../../shared/components/Input';
import Checkbox from '../../../../shared/components/Checkbox';
import DualActionButtons from '../../components/DualActionButtons';
import { useApi } from '../../../../shared/helpers/api';
import { useAuth } from '../../../../shared/helpers/auth';

import LoaderOverlay from '../../../../shared/components/LoaderOverlay';
import TextHelper from '../../../../shared/components/TextHelper';
import { apiRoutes } from '../../../../shared/helpers/apiRoutes';
import { seoRoutes } from '../../../../shared/helpers/innerRoutes';
import { useFetch, useKeyDown } from '../../../../shared/helpers/hooks';

import {
  StepEnum,
  isSignUpButtonDisabled,
  getConfirmationPasswordErrorMessage,
  getErrorMessage,
  passwordErrorsConfig,
  passwordInputValid,
} from './utils';
import { ReactComponent as CheckIcon } from '../../../../shared/assets/check.svg';

const { auth: { changePassword, signUp } } = apiRoutes;
const { importProfiles, login } = seoRoutes;

const SetPassword = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const { setAuth } = useAuth();
  const { setTokens } = useApi();
  const { showNotification } = useNotification();

  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});
  const [passwordConfirmationBlurred, setPasswordConfirmationBlurred] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const resetHash = location.state?.resetHash || '';
  const result = Object.fromEntries(new URLSearchParams(location.search).entries());
  const isResetPassword = Boolean(resetHash.length);
  const apiPath = isResetPassword ? changePassword : signUp;
  const apiPayload = isResetPassword
    ? { newPassword: form.password, codeHash: resetHash }
    : { code: result.code, password: form.password };

  const { isLoading, executeRequest, responseData } = useFetch({
    url: apiPath,
    payload: apiPayload,
    config: { useCache: false, fetchOnMount: false },
  });

  const { password, passwordConfirmation } = form;
  const title = resetHash.length ? t('setPassword.resetPassword') : t('setPassword.title');
  const confirmBtnLabel = resetHash.length ? t('setPassword.resetPassword') : t('setPassword.signUp');

  const goBackHandler = () => navigate(-1);

  const errMessage = getErrorMessage({ password, passwordConfirmation, passwordConfirmationBlurred });
  const errConfirmationPasswordMessage = getConfirmationPasswordErrorMessage({
    password,
    passwordConfirmation,
    passwordConfirmationBlurred,
  });

  const { isPasswordConfirmationValid, isPasswordValid } = passwordInputValid(errMessage, form, password);
  const readOnlySingUpButton = isSignUpButtonDisabled({ errMessage, errConfirmationPasswordMessage }) || Object.values(errors).some((el) => el);

  const passwordHelperMessage = t(errMessage.message) === t(passwordErrorsConfig.passwordIsOk)
    ? t(errConfirmationPasswordMessage.message)
    : t(errMessage.message);

  const confirmationPasswordBlurHandler = (val) => {
    setPasswordConfirmationBlurred(val);
  };

  const handleError = (name) => (value) => {
    setErrors((prev) => ({ ...prev, [name]: value }));
  };

  const checkIfPassworsdMatch = ({ pass, passConfirm }) => {
    setErrors((prev) => ({
      ...prev,
      passwordsMatch: pass !== passConfirm ? passwordErrorsConfig.passwordsAreDifferent : false,
    }));
  };

  const handleForm = (name) => (value) => {
    setForm((prev) => {
      if (name === StepEnum.password) {
        checkIfPassworsdMatch({ password: value, passwordConfirmation: prev.passwordConfirmation });
      } else {
        checkIfPassworsdMatch({ password: prev.password, passwordConfirmation: value });
      }
      return { ...prev, [name]: value };
    });
  };

  const handleSignUpSuccess = (data) => {
    const { session, token, refreshToken } = data;
    setTokens({ token, refreshToken });
    localStorage.setItem('token', token);
    localStorage.setItem('refreshToken', refreshToken);
    setAuth({
      id: session.id,
      login: session.login,
      businessProfiles: session.businessProfiles,
      isAuth: true,
    });
    navigate(importProfiles);
  };

  const handlePasswordChangeSuccess = () => {
    showNotification({ message: t('setPassword.passwordChanged'), type: 'success' });
    navigate(login);
  };

  const handleSetPassword = async () => {
    await executeRequest();
    if (apiPath === signUp && responseData) {
      handleSignUpSuccess(responseData);
    } else {
      handlePasswordChangeSuccess();
    }
  };

  const positiveButtonConfig = {
    label: confirmBtnLabel,
    disabled: readOnlySingUpButton,
    onClick: handleSetPassword,
    className: classes.button,
  };

  const negativeButtonConfig = {
    className: classes.backButton,
    label: t('setPassword.back'),
    onClick: goBackHandler,
  };

  useKeyDown({ callback: handleSetPassword, canInvoke: !readOnlySingUpButton, key: 'Enter' });

  return (
    <main className={classes.wrapper}>
      {isLoading && <LoaderOverlay customStyle={classes.loading} />}
      <form className={classes.form}>
        <h2 className={classes.text}>{title}</h2>
        <Input
          className={classes.input}
          label={t('setPassword.password')}
          value={form.password}
          onChange={handleForm('password')}
          onError={handleError('password')}
          showErrorAfterBlur
          type={showPassword ? 'text' : 'password'}
          testId="password"
        />
        <CheckIcon className={clsx(classes.checkIcon, isPasswordValid && classes.showCheck)} />
        <Input
          className={classes.input}
          label={t('setPassword.confirmPassword')}
          value={form.passwordConfirmation}
          onChange={handleForm('passwordConfirmation')}
          onBlur={() => confirmationPasswordBlurHandler(true)}
          onFocus={() => confirmationPasswordBlurHandler(false)}
          type="password"
          error={errMessage.errorPasswordConfirmation}
          testId="confirmPassword"
        />
        <CheckIcon className={clsx(classes.checkIcon, isPasswordConfirmationValid && classes.showCheck)} />
        <TextHelper message={passwordHelperMessage} />
        <Checkbox
          checkboxStyle={classes.checkboxStyle}
          onClick={() => setShowPassword((prev) => !prev)}
          labelPosition="right"
          className={classes.showPassword}
          label={t('setPassword.showPassword')}
          checkIconStyle={classes.checkIconStyle}
        />
        <DualActionButtons
          containerClassName={classes.buttons}
          positiveButton={positiveButtonConfig}
          negativeButton={negativeButtonConfig}
        />
      </form>
    </main>
  );
};

export default SetPassword;
