import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Input from '../../../../../shared/components/Input';
import ActionBox from '../ActionBox';
import classes from './styles.module.css';
import Button from '../../../../../shared/components/Button';
import PillWithIcon from '../../../../../shared/components/Pill/PillAlternative';
import Tooltip from '../../../../../shared/components/Tooltip';
import { useApi } from '../../../../../shared/helpers/api';
import { useNotification } from '../../../../../shared/helpers/notification';
import { ReactComponent as PlusIcon } from '../../../../../shared/assets/plus.svg';
import { ReactComponent as CloseIcon } from '../../../../../shared/assets/close.svg';
import SubInformation from '../../../../../shared/components/SubInformation';
import { handleApiError } from '../../Settings/ChangePassword/utils';
import { useKeyDown } from '../../../../../shared/helpers/hooks';
import LoaderOverlay from '../../../../../shared/components/LoaderOverlay';
import { apiRoutes } from '../../../../../shared/helpers/apiRoutes';

const MAX_LABELS_NUMBER = 10;
const MAX_LABEL_LENGTH = 50;

const Labels = ({ isEdit, readOnlyAction, businessProfileId, editHandler, form, setForm, adminPreview }) => {
  const { t } = useTranslation();
  const { showNotification } = useNotification();
  const { api } = useApi();
  const [label, setLabel] = useState('');
  const [labels, setLabels] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const readOnly = readOnlyAction || !isEdit;
  const shouldRenderInfo = labels.length === MAX_LABELS_NUMBER && isEdit;

  const handleLabel = (value) => {
    setLabel(value);
  };

  const addLabelHandler = () => {
    const sameLabel = labels.find((el) => el === label);
    if (sameLabel) {
      showNotification({
        message: t('businessProfileEdit.labelAlreadyExist'),
        type: 'warning',
      });
      setLabel('');
      return;
    }

    if (labels.length === MAX_LABELS_NUMBER) {
      showNotification({
        message: t('businessProfileEdit.tooManyLabels'),
        type: 'warning',
      });
    }
    if (!label) {
      return;
    }

    setLabels((prev) => [...prev, label]);
    setLabel('');
  };

  const handleDelete = (el) => {
    if (!isEdit) return;
    setLabels((prev) => prev?.filter((prevEl) => prevEl !== el));
  };
  useKeyDown({ key: 'Enter', callback: addLabelHandler });

  const handleSaveLabels = async () => {
    setIsLoading(true);
    try {
      const {
        data: { labels: newLabels },
      } = await api.post(apiRoutes.businessProfile.updateBusinessProfileLabels, {
        labels,
        businessProfileId,
      });
      setForm({ ...form, labels: newLabels });
      editHandler();
      showNotification({ label: t('global.success'), message: t('businessProfileEdit.labelsSaved'), type: 'success' });
    } catch (err) {
      handleApiError({ err, t, showNotification });
    } finally {
      setIsLoading(false);
      setLabel('');
    }
  };

  const handleCancel = () => {
    setLabel('');
    setLabels(form.labels);
    editHandler();
  };

  useEffect(() => {
    setLabels(form.labels);
  }, [form.labels]);

  return (
    <div className={classes.wrapper}>
      {isLoading && <LoaderOverlay customStyle={classes.loaderWrapper} />}
      <div className={classes.body}>
        {!adminPreview && (
          <div className={classes.inputWrapper}>
            <Input
              value={label}
              onChange={handleLabel}
              label={t('businessProfileEdit.addLabel')}
              readOnly={!isEdit || labels.length === MAX_LABELS_NUMBER}
              maxLength={MAX_LABEL_LENGTH}
              autoComplete="off"
            />
            <Tooltip
              isEdit={isEdit}
              text={t('businessProfileEdit.inOrderToAddLabel')}
              tooltipStyle={classes.tooltipStyle}
            >
              <Button
                onClick={addLabelHandler}
                iconStyle={classes.addIconStyle}
                className={classes.addBtn}
                Icon={PlusIcon}
                readOnly={!isEdit || labels.length === MAX_LABELS_NUMBER}
              />
            </Tooltip>
          </div>
        )}
        {Boolean(labels.length) && (
          <div className={classes.labelsList}>
            {labels.map((item) => (
              <PillWithIcon key={item} className={classes.pill}>
                {item}
                <CloseIcon
                  onClick={() => handleDelete(item)}
                  className={clsx(classes.closeIcon, isEdit && classes.activeIcon, adminPreview && classes.displayNone)}
                />
              </PillWithIcon>
            ))}
          </div>
        )}
        {shouldRenderInfo && (
          <SubInformation className={classes.maxLabels} label={t('businessProfileEdit.tooManyLabels')} />
        )}
      </div>
      {isEdit && <ActionBox handleCancel={handleCancel} handleSave={handleSaveLabels} readOnlyAction={readOnly} />}
    </div>
  );
};
export default Labels;
