import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import classes from './styles.module.css';
import Button from '../Button';
import SidebarUserMenu from '../SidebarUserMenu';
import Pill from '../Pill';
import { ReactComponent as BurgerMenu } from '../../assets/menu.svg';
import { ReactComponent as ChevronDown } from '../../assets/chevronDown.svg';
import { ReactComponent as SEOCard } from '../../assets/credit-card.svg';
import { ReactComponent as Notification } from '../../assets/bell.svg';
import { ReactComponent as User } from '../../assets/user.svg';
import { ReactComponent as Close } from '../../assets/close.svg';
import { ReactComponent as Logo } from '../../assets/logoIcon.svg';
import { useAuth } from '../../helpers/auth';
import { seoRoutes } from '../../helpers/innerRoutes';
import BadgeComponent from '../Badge';
import { NOTIFCATION_STATUS_ENUM } from '../../../app/SEO/views/Notifications/utils';
import { useApi } from '../../helpers/api';
import { apiRoutes } from '../../helpers/apiRoutes';
import { handleApiError } from '../../../app/SEO/modules/Settings/ChangePassword/utils';
import { useSSENotifications } from '../../helpers/hooks';
import NotificationFeed from '../../../app/SEO/components/NotificationFeed';

const { notViewed } = NOTIFCATION_STATUS_ENUM;
const notViewedRequestPayload = {
  filters: {
    limit: 5,
    offset: 0,
    status: [notViewed],
  },
};
const SidebarHeader = ({ businessProfile, onMobileMenuClick, showBusinessCards, showList, mobileNavVisible }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const settingsView = pathname === seoRoutes.settings;

  const navigate = useNavigate();

  const { api } = useApi();
  const sseNotifications = useSSENotifications();

  const { businessProfiles } = useAuth();
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [userNotification, setUserNotification] = useState([]);
  const { address, image, name, subscription } = businessProfile;
  const isLogoDisplayed = !businessProfiles.length && settingsView;
  const notificationNotSeen = userNotification?.filter((notification) => notification.status === notViewed).length;

  const navigateToCardList = () => {
    navigate(seoRoutes.opinieoCards);
  };

  const toggleUserMenu = () => {
    setShowUserMenu((prev) => !prev);
  };

  const toggleNotification = () => {
    setShowNotification((prev) => !prev);
  };

  const fetchNotifications = async () => {
    try {
      const {
        data: { results },
      } = await api.post(apiRoutes.notification.notificationList, notViewedRequestPayload);

      setUserNotification((prev) => {
        const combinedNotifications = [...prev, ...results];
        const uniqueNotifications = combinedNotifications.filter(
          (notification, index, self) => index === self.findIndex((n) => n.id === notification.id),
        );
        return uniqueNotifications.sort((a, b) => b.id - a.id);
      });
    } catch (error) {
      handleApiError({ error, t, showNotification });
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  useEffect(() => {
    if (sseNotifications.length) {
      setUserNotification((prev) => {
        const combinedNotifications = [...prev, ...sseNotifications];
        const uniqueNotifications = combinedNotifications.filter(
          (notification, index, self) => index === self.findIndex((n) => n.id === notification.id),
        );
        return uniqueNotifications.sort((a, b) => b.id - a.id);
      });
    }
  }, [sseNotifications]);

  useEffect(() => {
    const controller = new AbortController();
    const markNotificationsAsViewed = async () => {
      try {
        await api.post(
          apiRoutes.notification.updateIsViewed,
          {
            status: 'VIEWED',
            ids: userNotification.slice(0, 5).map((notification) => notification.id),
          },
          { signal: controller.signal },
        );
        await fetchNotifications();
      } catch (err) {
        handleApiError({ err, t, showNotification });
      }
    };

    if (showNotification && Boolean(notificationNotSeen)) {
      markNotificationsAsViewed();
    }

    return () => controller.abort();
  }, [showNotification]);

  return (
    <div className={classes.wrapper}>
      {showUserMenu && <SidebarUserMenu toggleUserMenu={toggleUserMenu} />}
      <div className={classes.rowContainer}>
        <div className={classes.leftSide}>
          {isLogoDisplayed && <Logo className={classes.logo} onClick={() => navigate(seoRoutes.dashboard)} />}
          <Button
            label={t('dashboard.opinieoCard')}
            Icon={SEOCard}
            className={classes.seoCardsBtn}
            onClick={navigateToCardList}
          />
        </div>
        {showNotification && (
        <NotificationFeed className={classes.notificationFeed} closeFeed={toggleNotification} data={userNotification} />
        )}
        <div className={classes.rightSide}>
          <div onClick={toggleNotification} className={classes.notificationIconWrapper}>
            <Notification className={classes.icon} />
            {Boolean(userNotification.length) && (
            <BadgeComponent
              label={userNotification.length >= 5 ? '+5' : notificationNotSeen}
              className={classes.notificationBadge}
            />
            )}

          </div>
          <div onClick={toggleUserMenu} className={classes.userIconWrapper}>
            <User className={classes.userIcon} />
          </div>
        </div>
      </div>
      {Boolean(businessProfiles.length) && (
        <div className={clsx(classes.header, mobileNavVisible && classes.navOpen)}>
          {mobileNavVisible ? (
            <Close onClick={onMobileMenuClick} className={clsx(classes.menu, classes.close)} />
          ) : (
            <BurgerMenu onClick={onMobileMenuClick} className={classes.menu} />
          )}
          <div className={classes.info}>
            {image && <img src={image} alt="business profile" className={classes.image} />}
            <div className={classes.businessDetails}>
              <Pill className={classes.subscriptionPill} label={t(`subscription.${subscription}Plan`)} />
              <div className={classes.name}>{name}</div>
              <div className={classes.address}>{address}</div>
            </div>
          </div>
          <ChevronDown
            onClick={showList}
            className={clsx(classes.chevronIconTopBar, showBusinessCards && classes.invertedIcon)}
          />
        </div>
      )}
    </div>
  );
};

export default SidebarHeader;
