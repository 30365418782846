export const seoRoutes = {
  welcome: '/welcome',
  importProfiles: '/pick_business_profiles',
  login: '/login',
  createBusinessProfileStandAlone: '/createBusinessProfile',
  setPassword: '/set_password',
  registerMethods: '/register_methods',
  remindPassword: '/remindPassword',
  reauthorization: '/reauthorization',
  dashboard: '/dashboard',
  createBusinessProfile: '/dashboard/createBusinessProfile',
  editBusinessProfile: '/dashboard/edit_business_profile',
  changeHistory: '/dashboard/edit_business_profile/history',
  settings: '/dashboard/settings',
  opinieoCards: '/dashboard/cards',
  reportList: '/dashboard/report_list',
  subscription: '/dashboard/subscription',
  notifications: '/dashboard/notifications',
  localPost: '/dashboard/post',
  opinions: '/dashboard/opinions',
  verification: '/dashboard/verification',
  invoiceData: '/dashboard/settings/invoices-data',
  paymentHistory: '/dashboard/settings/payment-history',
  answerTemplates: '/dashboard/opinions/answers',
  media: '/dashboard/media',
  report: '/report',
  setFilters: '/set_filters',
  aiConfig: '/dashboard/ai_config',
  aiConfigAssistant: (id) => `/dashboard/ai_config/${id}`,
  cardDetails: (id) => `/dashboard/cards/${id}`,
};

export const adminRoutes = {
  admin: '/admin',
  administrators: '/admin/administrators',
  managers: '/admin/managers',
  users: '/admin/users',
  cards: '/admin/cards',
  settings: '/admin/settings',
  auth: '/admin/auth',
  remindPassword: '/admin/auth/remindPassword',
  changePasswordByEmail: '/acp/user/changePasswordByEmail',
  cardDetails: (id) => `/admin/cards/${id}`,
  adminDetails: (id) => `/admin/administrators/${id}`,
};
